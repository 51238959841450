import React from 'react';
import './FamilyTree.css'

const FamilyTree = () => {
  return (
    <div className='family-tree'>
    <h1 className='family-tree-header'>
  My Family Tree
</h1>
      <iframe
        src="https://ac-ventures-family-tree.vercel.app/#/view?gen=0&indi=I0110&view=relatives"
        title="Topola Viewer"
      />
    </div>
  );
};

export default FamilyTree;
