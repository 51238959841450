import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import ViewMap from './pages/ViewMap';
import Sidebar from './Sidebar';
import FamilyTree from './pages/FamilyTree';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentUrl, setModalContentUrl] = useState('')
  console.log(isModalOpen)
  return (
    <BrowserRouter>
      <div style={{ display: 'flex' }}>
        {/* Sidebar */}
        <Sidebar setIsModalOpen={setIsModalOpen} setModalContentUrl={setModalContentUrl} />
        
        {/* Main content */}
        <div style={{ width: '100%' }}>
          <Routes>
            <Route path="/" element={<ViewMap />} />
            <Route path="/family-tree" element={<FamilyTree />} />
          </Routes>
        </div>

        {/* Modal content */}
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} center>
          <br></br>
          <p>Content coming soon!</p>
          {/* <img src={modalContentUrl} style={{ maxWidth: '100%', height: 'auto' }} ></img> */}
          
      </Modal>
      </div>
    </BrowserRouter>
  );
};

export default App;
