import {React, useState, useEffect} from 'react'
import NarrativeModal from './NarrativeModal';


const NarrativeContainer = ({narrative, setNarrative}) => {
  const [closeButton, setCloseButton] = useState(null)
  const [modal, setModal] = useState(null)

  useEffect(() => {
    if (closeButton == null) {
      return;
    }
    closeButton.focus();
  }, [closeButton])

  const closeModal = () => {
     setNarrative(null);
     toggleScrollLock();
  }

  const onClickOutside = (event) => {
    if (modal && modal.contains(event.target)) return;
    closeModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  toggleScrollLock();

    return (
      <NarrativeModal
        modalRef={setModal}
        buttonRef={setCloseButton}
        closeModal={closeModal}
        onClickOutside={onClickOutside}
        narrative = {narrative}
      />
  )
}

export default NarrativeContainer