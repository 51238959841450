import React from 'react';
import './Sidebar.css';
import { slide as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';


const Sidebar = (props) => {

  const {setIsModalOpen, setModalContentUrl} = props;

  return (

      <Menu>  
        <Link className="menu-item" to="/">
          Home
        </Link>
        <Link className="menu-item" to="/family-tree">
          Family Tree
        </Link>
        <a className="menu-item" target='_blank' href="wedding.html">
        Wedding
      </a>
        <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "Nocturne"
          </a>
          <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "The Other Side"
          </a>
          <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "Schooled"
          </a>
        
          <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "A Night of Quiet Stars"
          </a>
          <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "Immaculate Mother"
          </a>
          <a
            className="menu-item"
            onClick={() => {
              setModalContentUrl("")
              setIsModalOpen(true)
            }}

          >
            "Renewal"
          </a>
        
      </Menu>
    
  );
};

// Export the Sidebar component as the default export
export default Sidebar;