import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn } from 'mdb-react-ui-kit';

const NarrativeContent = ({narrative}) => {
    const renderDate = (dateToBeRendered) => {
      const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
      const renderedDate = new Date(dateToBeRendered).toLocaleString("en-US", dateFormatOptions);
      return renderedDate;
    }    

    const renderPhoto = () => {
      return (
        <React.Fragment>
          <div className="narrative-img-container">
          <a
            target='_blank'
            rel="noreferrer"
            href={narrative.properties.photoId}>
          <MDBCardImage
            className="mb-2 narrative-img"
            src={[narrative.properties.photoId]}
            position="top"
            alt={narrative.properties.title}
          />
          </a>
          </div>
        </React.Fragment>
      );
    }

    const renderVideo = () => {
      return (
        <React.Fragment>
          <div className="ratio ratio-16x9">
            <iframe
              className="narrative-video"
              src={narrative.properties.video}
              title={narrative.properties.title}
              allowFullScreen
            ></iframe>
          </div>
        </React.Fragment>
      );
    }

    return (

        <MDBCard className="text-start mb-0 h6 narrative-card">
          {
            narrative.properties.video ?
            renderVideo() :
            renderPhoto()
          }
          <MDBCardBody className="pt-0 narrative-body">
            <MDBCardText className="text-end pl-3 narrative-title">
              {narrative.properties.title}
            </MDBCardText>
            <MDBCardText className="note note-light text-start m-0 h6 narrative-text" style={{ whiteSpace: 'pre-line' }}>
              {narrative.properties.narrative}
            </MDBCardText>
            <MDBCardText className="text-end m-0 narrative-date">
              <small>{renderDate(narrative.properties.date)}</small>
            </MDBCardText>
            <MDBCardText className="text-end m-0 narrative-category" >
              <small>{narrative.properties.category}</small>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
    );
};

export default NarrativeContent;




