import React, { useRef, useEffect, useState } from 'react';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import NarrativeContainer from './NarrativeContainer';  // Import any needed components

const MapComponent = ({ viewport, setViewport, logEntries, selectedPhoto, setSelectedPhoto }) => {
  const mapRef = useRef();

  const points = logEntries.map(entry => ({
    type: "Feature",
    properties: {
      cluster: false,
      cluster_id: entry._id,
      photoId: entry.image,
      video: entry.video,
      category: entry.series,
      date: entry.date,
      title: entry.title,
      narrative: entry.narrative,
      slideShowContent: [6],
    },
    geometry: {
      type: "Point",
      coordinates: [entry.longitude, entry.latitude]
    }
  }));

  const bounds = mapRef.current && mapRef.current.getMap
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 18 }
  });

  return (
    <ReactMapGL
      {...viewport}
      maxZoom={16}
      mapStyle="mapbox://styles/monalimirel/ckklyb5wb106k17pcbm319lzv"
      mapboxApiAccessToken="pk.eyJ1IjoibW9uYWxpbWlyZWwiLCJhIjoiY2trMGswZWV2MGk4eDJ1bnl0ZDdsaDA4ZSJ9.mdT9AFU-tHTpGpRwHC1bPg"
      onViewportChange={setViewport}
      ref={mapRef}
    >
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              latitude={latitude}
              longitude={longitude}
            >
              <div
                className="cluster-marker"
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  setViewport({
                    ...viewport,
                    latitude,
                    longitude,
                    zoom: expansionZoom,
                    transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
                    transitionDuration: 'auto'
                  });
                }}
              >
                <img style={{ width: '50px', borderRadius: '50%' }} src='./acv.png' alt="ac ventures logo" />
              </div>
              <div
                className="cluster-count"
                style={{
                  color: 'blue',
                  width: `${10 + (pointCount / points.length) * 20}px`,
                  height: `${10 + (pointCount / points.length) * 20}px`
                }}
              >
                {pointCount}
              </div>
            </Marker>
          );
        }

        return (
          <Marker key={cluster.properties.cluster_id} latitude={latitude} longitude={longitude}>
            <button
              className="marker-btn"
              onClick={e => {
                e.preventDefault();
                setSelectedPhoto(cluster);
              }}
            >
              <div>
                <img src={cluster.properties.photoId} alt={cluster.properties.cluster_id} />
              </div>
            </button>
          </Marker>
        );
      })}

      {selectedPhoto ? (
        <NarrativeContainer narrative={selectedPhoto} setNarrative={setSelectedPhoto} />
      ) : null}
    </ReactMapGL>
  );
};

export default MapComponent;
