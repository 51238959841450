// src/pages/ViewMap.js
import React, { useState, useEffect } from 'react';
import MapComponent from '../MapComponent'; // Import the MapComponent
import { listLogEntries } from '../API'; // Import the function to get log entries

const ViewMap = () => {
  const [logEntries, setLogEntries] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [viewport, setViewport] = useState({
    width: '100vw',
    height: '100vh',
    latitude: 37.8,
    longitude: -122.4,
    zoom: 0
  });

  useEffect(() => {
    const fetchLogEntries = async () => {
      const entries = await listLogEntries();
      setLogEntries(entries);
    };

    fetchLogEntries();
  }, []);

  return (
    <div>
      {/* Render the MapComponent with the required props */}
      <MapComponent
        viewport={viewport}
        setViewport={setViewport}
        logEntries={logEntries}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
      />
    </div>
  );
};

export default ViewMap;
